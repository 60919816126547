.info{
    text-align: left;
    color: #333;
    background: #cccccc;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
    border-top: 1px solid #f4f4f4;
    font-size: 13px;
}
table td, .form_create .box table th {
    padding: 5px 10px !important;
}
.table-danhsach td {
    border: 1px solid rgba(0,0,0,0.03) !important;
}