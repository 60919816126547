.yoast-seo{
    border-bottom: 1px hidden rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px;
    font-family: Arial, Roboto-Regular, HelveticaNeue, sans-serif;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 40px;
    .dlCkVf:first-child {
        margin-bottom: -16px;
    }
    .dlCkVf {
        padding: 12px 16px;
        .screen-reader-text{
            clip: rect(1px, 1px, 1px, 1px);
            position: absolute;
            height: 1px;
            width: 1px;
            overflow: hidden;
        }
        .joWiup {
            display: inline-block;
            cursor: pointer;
            position: relative;
            max-width: 90%;
            white-space: nowrap;
            font-size: 14px;
            line-height: 16px;
            vertical-align: top;
            .hgXshB {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                margin-bottom: 12px;
                padding-top: 1px;
                line-height: 20px;
                vertical-align: top;
                .hHABJO {
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                    border-radius: 50px;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    background: rgb(241, 243, 244);
                    min-width: 28px;
                    .fTqTJk {
                        width: 18px;
                        height: 18px;
                        margin: 0px 5px;
                        vertical-align: middle;
                    }
                }
                .feGpeb {
                    font-size: 12px;
                    line-height: 20px;
                    color: rgb(112, 117, 122);
                    .gYNhjI {
                        font-size: 14px;
                        color: black;
                    }
                    .bZphhk {
                        color: rgb(32, 33, 36);
                    }
                }
            }
        }
        .gbobMG {
            color: rgb(60, 64, 67);
            font-size: 14px;
            cursor: pointer;
            position: relative;
            max-width: 600px;
            .hThKVW {
                color: rgb(112, 117, 122);
            }
        }
        .gbobMG::after {
            display: table;
            content: "";
            clear: both;
        }
    }
    
}
.dwZvnc {
    color: rgb(21, 88, 214);
    text-decoration: none;
    font-size: 20px;
    line-height: 26px;
    font-weight: normal;
    margin: 0px;
    display: inline-block;
    overflow: hidden;
    max-width: 600px;
    vertical-align: top;
    text-overflow: ellipsis;
}
.igrcjV {
    max-width: 600px;
    vertical-align: top;
    text-overflow: ellipsis;
}
.fbVpha {
    display: inline-block;
    max-height: 52px;
    padding-top: 1px;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
}
.block-editor__container img {
    max-width: 100%;
    height: auto;
}
