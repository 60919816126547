.theme-container {
    margin: 0 5px;

    &.active {
        background-color: #bae0ff;
        box-shadow: 0 2px 1px -1px #bae0ff, 0 1px 1px 0 #bae0ff, 0 1px 3px #bae0ff;
    }

    .theme {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border: 1px solid #ced4da;
        padding: 2px;
        display: grid;
        margin: 0 auto;

        .theme-row {
            width: 100%;
            height: 100%;
            display: flex;

            .theme-col {
                padding: 0.5px !important;

                .item {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #ced4da;
                    background-color: white;

                    &.no-edit {
                        background-color: #ced4da;
                    }
                }
            }
        }
    }
}
.row {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 0;
    flex-wrap: wrap;
}
.row>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)/ 2);
    padding-left: calc(var(--bs-gutter-x)/ 2);
    margin-top: var(--bs-gutter-y);
}
.row>* {
    position: relative;
}
.no-margin {
    margin: 0!important;
}
.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
}
.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
}
.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
}
.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
}