.inner-item {
    cursor: all-scroll;
    width: 100%;
    min-height: 36px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f9fa;
    padding: 8px 4px;
}
.index-slider{
    width: 19px;
    height: 19px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    color: #5156be;
    background-color: rgba(81,86,190,.18);
    border-radius: 50;
    align-items: center;
    font-weight: 500;
}