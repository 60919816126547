.text-red {
    color: red !important;
}

.p-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.drop-content {
    height: calc(100% - 30px);
}

.p-menu {
    padding: 0.5rem 0;
    background: #ffffff;
    color: #212529;
    border-radius: 4px;
    width: 12.5rem;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        list-style-position: initial;
        list-style-image: initial;
        list-style-type: none;
    }

    .p-menu-overlay {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: none;
    }

    .p-menuitem-link {
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-decoration: none;
        overflow: hidden;
        width: 100%;

        .p-menuitem-icon {
            color: #212529;
            margin-right: 0.5rem;
        }
    }
}

.p-0 {
    padding: 0px;
}

.col-custom {
    background: white;
    border-radius: 2px;

    .left {
        // overflow-y: auto;
        // max-height: 600px;
    }

    .drop-here {}
}

.content-here {
    min-height: 43px;
    padding: 5.5px;
    justify-content: center;
    align-items: center;
    border: 1px dashed;
    background: #ced4da61;
    border-radius: 5px;
    height: 100%;

    :deep {

        .list-group:empty,
        .list-group>div:empty {
            height: 100%;
            padding: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .list-group:empty:before,
        .list-group>div:empty:before {
            content: 'Drop here';
        }
    }
}

.dropdown-item {
    .pi-pencil {
        color: var(--bs-cyan);
    }

    .pi-trash {
        color: var(--bs-danger);
    }
}


.not-draggable {
    cursor: no-drop;
}

.row-content-notborder {
    width: 100%;
    text-align: center;
}

.row-content {
    width: 100%;
    border: 2px dashed;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
}

.function-item {
    border-radius: 5px;
    margin: 4px;
    width: 100%;
    :deep {
        .p-splitbutton {
            width: 100%;
            height: 28px;

            .p-splitbutton-defaultbutton {
                text-align: left;
                cursor: all-scroll;
            }
        }
    }
}

.buttons {
    margin-top: 35px;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.select-theme {
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.dark-theme {
    .content-here {
        background: var(--dark-theme);
    }
    .function-item {
        // background: var(--dark-input-bg);
        // color: var(--dark-input-color);
        // border-style: var(--dark-input-border);
    }
}

.p-col-12 {
    width: 100%;
}
.fw-600 {
    font-weight: 600!important;
    text-align: center;
}

.p-col-4 {
    width: 33.3333%;
}
.p-col-6 {
    width: 50%;
}
.p-col-8 {
    width: 66.6667%;
}
.p-col-9 {
    width: 75%;
}
.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
}
.p-grid {
    display: flex;
    flex-wrap: wrap;
    // margin-right: -0.5rem;
    // margin-left: -0.5rem;
    // margin-top: -0.5rem;
}

.p-col-1, .p-col-2, .p-col-3, .p-col-4, .p-col-5, .p-col-6, .p-col-7, .p-col-8, .p-col-9, .p-col-10, .p-col-11, .p-col-12, .p-col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
}
.row-content {
    width: 100%;
    border: 2px dashed;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
}

.function-item {
    .func-item{
            position: relative;
            cursor: all-scroll;
            text-align: left;
            min-height: 28px!important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;
        }
}
.btn-secondary {
    color: #fff !important;
    background-color: #74788d !important;
    border-color: #74788d !important;
}
.btn-secondary {
    -webkit-box-shadow: 0 2px 6px 0 rgba(116,120,141,.5);
    box-shadow: 0 2px 6px 0 rgba(116,120,141,.5);
}
.dropdown-toggle {
    white-space: nowrap;
}
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.47rem 0.75rem;
    font-size: .875rem;
    border-radius: 0.25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
// Module
.list-module-group {
    height: auto;
    max-height: 350px;
    min-height: 350px;
    overflow-y: auto;
    padding: 5px;
    border: 1px 1px 1px 1px solid #b9c4cc;
    border-color: #b9c4cc;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
}
.cp {
    cursor: pointer!important;
}

.list-module {
    margin-bottom: 5px;
    button {
        width: 100%;
        height: 28px;
        min-height: 28px;
        display: flex;
        align-items: center;
        cursor: move;
    }
}

.dark-theme {

    .col-custom {
        background: var(--dark-theme);
    }

    .list-module-group {
        border-style: var(--dark-input-border);
    }
}