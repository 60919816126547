@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/variables.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 6rem;
  transition: ease-in-out;
  transition-duration: 300ms;
  font-family: Arial, Helvetica, sans-serif;
  background: #edebe9;
}


select {
  /*for firefox*/
  -moz-appearance: none;
  /*for chrome*/
  -webkit-appearance:none;
  appearance: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}



input:focus,
select:focus,
textarea:focus,
button:focus {
outline: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes headerGrowth {
  from {
      opacity: 0;
      transform: scale(0);
  }
  to {
      opacity: 1;
      transform: scale(1)
  }
}


/* ANT DESIGN  */


thead[class*='ant-table-thead'] {
  box-sizing: border-box;
  border-radius: 29px !important;
}

th {
  border-top: 1px solid #ccc !important;
  padding: 15px 5px !important;
 
}

.ant-btn-primary{
  background-color: #1677ff;
}

.themes-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 5px;
}

/* atn-button */
.ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.action .ant-btn {
  font-size: 14px;
  height: 30px;
  width: 30px;
  padding: 4px 15px;
  border-radius: 6px;
}
/* ckeditor */
.ck-editor__editable:not(.ck-editor__nested-editable) { 
  min-height: 400px;
}

/* Modal media */

span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left{
  left: -24px;
}

.media .ant-tabs-tab{
 margin-left: 0 !important;
 border-radius:0 !important;
}
ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.text-base.css-dev-only-do-not-override-ph9edi{
  border-inline-end:0  !important ;
}

span.ant-upload.ant-upload-btn {
  height: 70vh !important;
}
span.ant-checkbox-inner{
 overflow: hidden;
}

.active .ant-checkbox-inner::after{
  color:white !important;
  font-size: 20px;
}

.active:hover span.ant-checkbox-inner::after{
  content: '\292B';
  top:-5px;
  left: -0.5px;
  border:transparent;
  transform: rotate(0deg);
  background-color: #2977ff !important;
}

.active2 .ant-checkbox-inner::after{
  color:white !important;
  font-size: 20px
}

.active2  .ant-checkbox-checked:after{
  border-color:#cbc8c5 !important  ;
}
.active2.active  .ant-checkbox-checked:after{
  border-color:transparent !important  ;
}
.active2:hover span.ant-checkbox-inner::after{
  content: '\292B';
  top:-5px;
  left: -0.5px;
  border:transparent;
  transform: rotate(0deg);
  background-color: #cbc8c5;
  border-color:#cbc8c5 ;
}

.active2 span.ant-checkbox-inner {
  background-color: #cbc8c5 ;
  border-color:#cbc8c5 ;
}

.active span.ant-checkbox-inner {
  background-color: #2977ff ;
  border-color:#2977ff  ;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: transparent !important;

}

/* video */
.video-react.video-react-fluid{
  border-radius: 10px ;
}
.video-react .video-react-poster{
  background-size: cover;
}

.ck-editor ul{
  list-style: revert !important;
  padding: revert !important;
  margin: revert !important;
}
.ck-editor ol{
  list-style: revert !important;
  padding: revert !important;
  margin: revert !important;
}

.ant-modal-body{
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.main-menu.ant-menu-light{
  color: #ffffff;
    background: none;
}
.ant-menu-title-content{
  text-align: left;
}
.ant-menu-submenu-title{
  padding-left: 10px !important;
  font-weight: 700;
  font-size: 15px;
  margin-left: 1rem;
}
.main-menu.ant-menu-light .ant-menu-item-selected{
  color: #ffffff;
  background-color:#EACF19;
}
.main-menu.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color: #EACF19;
}
.image-table{
  height: 70px;
  min-height: 70px;
  max-width: 100px;
  background: #ecececa6;
}
.ant-menu-item:not(.ant-menu-item-selected):hover{
  background:none !important;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), :where(.css-dev-only-do-not-override-ph9edi).ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
  color: white !important;
}
img {
  object-fit: contain;
}
:where(.css-1fviqcj).ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), :where(.css-1fviqcj).ant-menu-light>.ant-menu .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), :where(.css-1fviqcj).ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), :where(.css-1fviqcj).ant-menu-light>.ant-menu .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected){
  color: white !important;
}
:where(.css-ph9edi).ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), :where(.css-ph9edi).ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected){
  color: white !important;
}